import { putServerRequest,  getServerRequest, deleteServerRequest, patchServerRequest } from '@/components/globals/services/serverRequest.service';
import { store } from '../../store/store';
const httpURL = store.getters.httpURL;


export const cargarAvisosService = async() => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/avisos`;
    return await getServerRequest(url, config);
}

export const crearAvisoService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/aviso`;
    return await putServerRequest(url, data, config);
}

export const modificarAvisoService = async (idAviso, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/aviso/${idAviso}`;
    return await patchServerRequest(url, data, config);
  }

export const modificarVisibleAvisoService = async (idAviso, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/aviso/${idAviso}/visible`;
    return await patchServerRequest(url, data, config);
}

export const eliminarAvisoMasterService = async (idAviso) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/aviso/${idAviso}`;
    return await deleteServerRequest(url, config);
}


export const obtenerEscuelasService= async () => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/avisos/listadoEscuelas`;
    return await getServerRequest(url, config);
}

export const obtenerEmpresasService = async () => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/avisos/listadoEmpresas`;
    return await getServerRequest(url, config);
}

