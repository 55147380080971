<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dense
        flat
        dark
        class="text-h6"
        :loading="loading"
        >Avisos
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="crearAviso = true">
              <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar aviso</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <v-card outlined>
        <v-container v-if="loading">
          <v-row>
            <v-col md="12" sm="12">
              <v-card outlined class="my-2">
                <v-skeleton-loader
                  type="list-item-avatar-two-line@3"
                  style="margin: 20px"
                ></v-skeleton-loader>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="avisos.length == 0 && !loading">
          <v-card outlined class="text-left">
            <v-card-subtitle>No se han registrado avisos.</v-card-subtitle>
          </v-card>
        </v-container>

        <v-container>
          <v-card outlined v-for="(aviso, index) in avisos" :key="index">
            <avisoComponent 
              :aviso="aviso"
              @avisoVisibleModificado="avisoVisibleModificado"
              @avisoModificado="avisoModificado"
              @avisoEliminado="avisoEliminado"
            />
          </v-card>
        </v-container>
      </v-card>


    </v-card>
    
    <!-- Otros componentes -->
    <crearAvisoComponent 
      v-if="crearAviso"
      :mostrar="crearAviso"
      @cancelar="crearAviso = false"
      @nuevoAviso="nuevoAviso"
    />

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import avisoComponent from "./aviso.vue";
import crearAvisoComponent from "./crearAviso.vue";
import { cargarAvisosService } from "./avisos.service.js";

export default {
  name: 'avisosView',
  components: {
    avisoComponent,
    crearAvisoComponent
  },
  computed: {
    ...mapGetters(["sessionToken"]),
  },
  data () {
    return {
      loading: false,
      crearAviso: false,
      avisos: [],
    }
  },
  watch: {
    sessionToken(value) {
      if (value) this.cargarAvisos();
    },
  },
  async mounted() {
    if (this.sessionToken) this.cargarAvisos();
  },
  methods: {
    async cargarAvisos() {
      try {
        this.loading = true;
        
        const serverResponse = await cargarAvisosService();
        
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.avisos = serverResponse.avisos.reverse();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    nuevoAviso(data) {
      this.avisos.unshift(data);
      this.crearAviso = false;
    },
    avisoVisibleModificado(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id == data.idAviso);
      this.avisos[indexAviso].visible = data.visible;
    },
    avisoModificado(data) {
      const indexAviso = this.avisos.findIndex((aviso) => aviso._id == data._id);
      this.avisos[indexAviso].titulo = data.titulo;
      this.avisos[indexAviso].descripcion = data.descripcion;
      this.avisos[indexAviso].paraCampus = data.paraCampus;
      this.avisos[indexAviso].escuelas = data.escuelas;
      this.avisos[indexAviso].empresas = data.empresas;
    },
    avisoEliminado(idAviso) {
      const index = this.avisos.findIndex((aviso) => aviso._id == idAviso);
      if(index >= 0) this.avisos.splice(index, 1);
    },
  }
}
</script>