import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dense":"","flat":"","dark":"","loading":_vm.loading}},[_vm._v("Avisos "),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.crearAviso = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-playlist-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar aviso")])])],1),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e(),_c(VCard,{attrs:{"outlined":""}},[(_vm.loading)?_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"12","sm":"12"}},[_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-two-line@3"}})],1)],1)],1)],1):_vm._e(),(_vm.avisos.length == 0 && !_vm.loading)?_c(VContainer,[_c(VCard,{staticClass:"text-left",attrs:{"outlined":""}},[_c(VCardSubtitle,[_vm._v("No se han registrado avisos.")])],1)],1):_vm._e(),_c(VContainer,_vm._l((_vm.avisos),function(aviso,index){return _c(VCard,{key:index,attrs:{"outlined":""}},[_c('avisoComponent',{attrs:{"aviso":aviso},on:{"avisoVisibleModificado":_vm.avisoVisibleModificado,"avisoModificado":_vm.avisoModificado,"avisoEliminado":_vm.avisoEliminado}})],1)}),1)],1)],1),(_vm.crearAviso)?_c('crearAvisoComponent',{attrs:{"mostrar":_vm.crearAviso},on:{"cancelar":function($event){_vm.crearAviso = false},"nuevoAviso":_vm.nuevoAviso}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }