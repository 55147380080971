<template>
  <v-dialog v-model="mostrar" width="600" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark class="text-h6">
        <strong>{{ aviso ? "Modificar aviso" : "Crear aviso" }}</strong>
      </v-toolbar>

      <v-container>
        <v-card elevation="0">
          <v-container fluid>
            <v-text-field
              v-model="titulo"
              label="Título"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.titulo"
              @input="validarTitulo()"
              @blur="validarTitulo()"
            ></v-text-field>

            <v-textarea
              v-model="descripcion"
              label="Descripción"
              append-icon="mdi-comment-plus-outline"
              outlined
              :disabled="loading"
              rows="5"
              no-resize
              :error-messages="errors.descripcion"
              @input="validarDescripcion()"
              @blur="validarDescripcion()"
            ></v-textarea>

            <v-row dense>
              <v-col cols="12" md="4">
                <v-select
                  v-model="paraCampus" 
                  :items="opciones"
                  item-text="text"
                  item-value="value"  
                  :disabled="loading"
                  dense
                  label="Para campus"
                  outlined
                ></v-select>  
              </v-col> 

              <v-col cols="12" md="12">
                <v-select
                  v-model="selectedEscuelas" 
                  :items="escuelas"
                  item-text="nombre"
                  item-value="_id"    
                  :dense="selectedEscuelas.length === 0"
                  :loading="loadingEscuelas"
                  :disabled="paraCampus || loading || loadingEscuelas"
                  label="Escuelas"
                  no-data-text="No hay escuelas registradas"
                  outlined
                  multiple
                >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleEscuelas"
                  >
                    <v-list-item-action>
                      <v-icon :color="selectedEscuelas.length > 0 ? 'blue-grey darken-4' : ''">
                        {{ iconEscuelas }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Seleccionar todas
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

                <!-- Show items -->
                <template v-slot:selection="{ item }">
                  <v-chip outlined label color="blue-grey darken-4">
                    {{ item.nombre }}
                  </v-chip>
                </template>
                </v-select>  
              </v-col>

              <v-col cols="12" md="12">
                <v-select 
                  v-model="selectedEmpresas" 
                  :items="empresas"
                  item-text="nombre"
                  item-value="_id"   
                  :dense="selectedEmpresas.length === 0"
                  :loading="loadingEmpresas" 
                  :disabled="paraCampus || loading || loadingEmpresas"
                  label="Empresas"
                  no-data-text="No hay empresas registradas"
                  outlined
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleEmpresas"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedEmpresas.length > 0 ? 'blue-grey darken-4' : ''">
                          {{ iconEmpresas }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Seleccionar todas
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <!-- Show items -->
                  <template v-slot:selection="{ item }">
                    <v-chip outlined label color="blue-grey darken-4">
                      {{ item.nombre }}
                    </v-chip>
                  </template>
                </v-select>  
              </v-col> 

            </v-row>

          </v-container>
        </v-card>
      </v-container>

      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
        >Cancelar
        </v-btn>
        <v-btn
          small
          color="primary"
          :loading="loading || loadingEscuelas || loadingEmpresas"
          :class="{
            'disable-events': blockBtn,
          }"
          @click="submit()"
        >{{ aviso ? "Modificar" : "Crear"}}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { obtenerEscuelasService, obtenerEmpresasService, crearAvisoService, modificarAvisoService } from "./avisos.service.js";

export default {
  name: 'crearAviso',
  props: {
    mostrar: { type: Boolean, default: false },
    aviso: { type: Object, default: () => null },
  },
  mixins: [validationMixin],
  validations: {
    titulo: { required },
    descripcion: { required },
  },
  watch: {
    aviso() {
      if(this.aviso) this.setData();
    },
    paraCampus(value) {
      if(value) { 
        this.selectedEscuelas = [];
        this.selectedEmpresas = [];
       }
    },
  },
  computed: {
    allEscuelas () {
      return this.selectedEscuelas.length === this.escuelas.length;
    },
    someEscuelas () {
      return this.selectedEscuelas.length > 0 && !this.allEscuelas;
    },
    iconEscuelas () {
      if (this.allEscuelas) return 'mdi-close-box'
      if (this.someEscuelas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allEmpresas () {
      return this.selectedEmpresas.length === this.empresas.length;
    },
    someEmpresas () {
      return this.selectedEmpresas.length > 0 && !this.allEmpresas;
    },
    iconEmpresas () {
      if (this.allEmpresas) return 'mdi-close-box'
      if (this.someEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    blockBtn() {
      if(this.errors.titulo.length > 0 || this.errors.descripcion.length > 0) return true;
      if(this.titulo.length == 0 || this.descripcion.length == 0) return true;
      if(!this.paraCampus)
        if(this.selectedEscuelas.length === 0 && this.selectedEmpresas.length === 0)
          return true;
      return false;
    }
  },
  data () {
    return {
      loading: false,
      loadingEscuelas: false,
      loadingEmpresas: false,
      titulo:'',
      descripcion:'',
      paraCampus:false,
      selectedEscuelas:[],
      selectedEmpresas:[],
      errors: {
        titulo: [],
        descripcion: []
      },
      opciones: [
        {text: "NO", value: false},
        {text: "SI", value: true},
      ],
      escuelas: [],
      empresas: [],
    }
  },
  created() {
    this.cargarEscuelas();
    this.cargarEmpresas();
    if(this.aviso) this.setData();
  },
  methods: {
    setData() {
      this.titulo = this.aviso.titulo;
      this.descripcion = this.aviso.descripcion;
      this.paraCampus = this.aviso.paraCampus;
      this.selectedEscuelas = this.aviso.escuelas;
      this.selectedEmpresas = this.aviso.empresas;
    },
    validarTitulo() {
      this.$v.titulo.$touch();
      const errors = [];
      if (this.$v.titulo.$dirty) {
        !this.$v.titulo.required && errors.push("Campo requerido.");
      }
      this.errors.titulo = errors; 
    },
    validarDescripcion() {
      this.$v.descripcion.$touch();
      const errors = [];
      if (this.$v.descripcion.$dirty) {
        !this.$v.descripcion.required && errors.push("Campo requerido.");
      }
      this.errors.descripcion = errors; 
    },
    async cargarEscuelas() {
      try {
        this.loadingEscuelas = true;
        const serverResponse = await obtenerEscuelasService();
        this.loadingEscuelas = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else 
          this.escuelas = serverResponse.escuelas;
    
      } catch (error) {
        this.loadingEscuelas = false;
        this.$appErrorMessage();
      }
    },
    async cargarEmpresas() {
      try {
        this.loadingEmpresas = true;
        const serverResponse = await obtenerEmpresasService();
        this.loadingEmpresas = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else 
          this.empresas = serverResponse.empresas;
    
      } catch (error) {
        this.loadingEmpresas = false;
        this.$appErrorMessage();
      }
    },
    async submit() {
      try {
        this.loading = true;
        const data = {
          titulo: this.titulo,
          descripcion: this.descripcion,
          paraCampus:  this.paraCampus,
          escuelas:  this.selectedEscuelas,
          empresas:  this.selectedEmpresas
        };

        const serverResponse = this.aviso
        ? await modificarAvisoService(this.aviso._id, data)
        : await crearAvisoService(data);

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.aviso
          ? this.$emit("avisoModificado", serverResponse.avisoModificado)
          : this.$emit("nuevoAviso", serverResponse.nuevoAviso);
        }
        
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }

    },
    toggleEscuelas () {
        this.$nextTick(() => {
          if (this.allEscuelas) {
            this.selectedEscuelas = []
          } else {
            this.selectedEscuelas = this.escuelas.map(e => e._id)
          }
        })
    },
    toggleEmpresas () {
        this.$nextTick(() => {
          if (this.allEmpresas) {
            this.selectedEmpresas = []
          } else {
            this.selectedEmpresas = this.empresas.map(e => e._id)
          }
        })
    },
  }
}
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}

.errorMessage {
  color: #ff0000;
}
.invalid_input {
  border: 1px solid red;
}

.link {
  text-decoration: none;
}

</style>