<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" outlined>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="$emit('modificarAviso')">
          <v-list-item-title>Modificar aviso</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-square-edit-outline</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-item @click="$emit('eliminarAviso')">
          <v-list-item-title>
            <font color="#D32F2F">Eliminar aviso</font>
          </v-list-item-title>
          <v-list-item-icon><v-icon color="red darken-2" small>mdi-trash-can-outline</v-icon></v-list-item-icon>
        </v-list-item>
      </v-list>

    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'avisosMenu',
  props: {
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
  }
}
</script>