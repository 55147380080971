<template>
  <v-card outlined class="margen-aviso" :loading="loading">
    <v-list-item>
      <v-list-item-action  class="title-action">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
          <v-icon large v-on="on" v-if="aviso.visible"  color="green" style="margin-right: 5px;" @click="modificarVisible()">mdi-toggle-switch</v-icon>
          <v-icon large v-on="on" v-else color="grey" style="margin-right: 5px;" @click="modificarVisible()">mdi-toggle-switch-off</v-icon>
          </template>
          <span v-if="aviso.visible">Ocultar aviso</span>
          <span v-else>Mostrar aviso</span>
        </v-tooltip>
      </v-list-item-action>


      <v-list-item-content>
        <v-list-item-title><strong>{{ aviso.titulo }}</strong></v-list-item-title>
        <v-list-item-subtitle>{{ moment(aviso.fecha).format("DD [de] MMMM [de] YYYY") }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="aviso.paraCampus">
          <v-chip 
            color="blue-grey darken-4"
            outlined
            small
          ><v-icon small color="blue-grey darken-4" left>mdi-bullhorn</v-icon>
          Enviado para campus</v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action >
        <avisoMenuComponent
          @modificarAviso="modificarAviso"
          @eliminarAviso="eliminarAviso"
        />
      </v-list-item-action>
    </v-list-item>

    <v-card-text class="body-1" style="white-space: pre-wrap;">{{ aviso.descripcion }}</v-card-text>
     
    <v-container v-if="!aviso.paraCampus" justify>
      <v-row>
        <!-- Escuelas -->
        <v-col cols="6">
          <v-card outlined>
            <v-list-item>
              <v-icon left>mdi-school</v-icon>
              Escuelas:
              <v-list-item-title class="d-flex flex-wrap">
                <v-chip v-if="aviso.escuelas.length == 0" color="blue-grey darken-4" outlined small class="ml-2">No se han asignado escuelas</v-chip>
                <v-chip
                  v-else
                  v-for="(escuela) in aviso.escuelas" 
                  :key="escuela._id"
                  outlined
                  small
                  color="blue-grey darken-4"
                  class="mt-1 ml-2"
                >{{ escuela.nombre }}
                </v-chip>
              </v-list-item-title>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- Empresas -->
        <v-col cols="6">
          <v-card outlined>
            <v-list-item>
              <v-icon left>mdi-office-building</v-icon>
              Empresas:
              <v-list-item-title class="d-flex flex-wrap">
                <v-chip v-if="aviso.empresas.length == 0" color="blue-grey darken-4" outlined small class="ml-2">No se han asignado empresas</v-chip>
                <v-chip
                  v-else
                  v-for="(empresa) in aviso.empresas" 
                  :key="empresa._id"
                  outlined
                  small
                  color="blue-grey darken-4"
                  class="mt-1 ml-2"
                >{{ empresa.nombre }}
                </v-chip>
              </v-list-item-title>
            </v-list-item>
          </v-card>
        </v-col>

      </v-row>
    </v-container>

    <crearAvisoComponent 
      v-if="editarAviso.mostrar"
      :mostrar="editarAviso.mostrar"
      :aviso="editarAviso.aviso"
      @cancelar="cancelarModificar"
      @avisoModificado="avisoModificado"
    />

    <eliminarAvisoMasterComponent 
      v-if="eliminar.mostrar"
      :mostrar="eliminar.mostrar"
      :aviso="eliminar.aviso"
      @cancelar="cancelarEliminar"
      @avisoEliminado="avisoEliminado"
    />

  </v-card>
</template>

<script>
import moment from "moment-timezone";
moment.locale('es');

import avisoMenuComponent from "./avisoMenu.vue";
import crearAvisoComponent from "./crearAviso.vue";
import eliminarAvisoMasterComponent from "./eliminarAvisoMaster.vue";
import { modificarVisibleAvisoService } from "./avisos.service.js";

export default {
  name: 'avisoMaster',
  props: {
    aviso: { type: Object, default: () => {} },
  },
  components: {
    avisoMenuComponent,
    crearAvisoComponent,
    eliminarAvisoMasterComponent
  },
  data() {
    return {
      loading: false,
      editarAviso: {
        mostrar: false,
        aviso: null
      },
      eliminar: {
        mostrar: false,
        aviso: null
      }
    };
  },
  created() {
    this.moment = moment;
  },
  methods: {
    async modificarVisible() {
      try {
        this.loading = true;
        const data = { visible: !this.aviso.visible};

        const serverResponse = await modificarVisibleAvisoService(this.aviso._id, data);
        this.loading = false;
        if(!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const data = {
            idAviso: this.aviso._id,
            visible: serverResponse.visible
          };
          this.$emit("avisoVisibleModificado", data);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    modificarAviso() {
      this.editarAviso.aviso = this.aviso;
      this.editarAviso.mostrar = true;
    },
    eliminarAviso() {
      this.eliminar.aviso = this.aviso;
      this.eliminar.mostrar = true;
    },
    cancelarModificar() {
      this.editarAviso.aviso = null;
      this.editarAviso.mostrar = false;
    },
    cancelarEliminar() {
      this.eliminar.aviso = null;
      this.eliminar.mostrar = false;
    },
    avisoModificado(data) {
      this.editarAviso.aviso = null;
      this.editarAviso.mostrar = false;
      this.$emit("avisoModificado", data);
    },
    avisoEliminado(idAviso) {
      this.eliminar.aviso = null;
      this.eliminar.mostrar = false;
      this.$emit('avisoEliminado', idAviso);
    },
  }

};

</script>

<style>
.title-action {
  margin-right: 5px !important;
}
.margen-aviso {
  margin: 10px;
}
.margen-visto {
  margin-right: 15px !important;
  margin-bottom: 10px !important;
}
</style>